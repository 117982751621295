import { AuthTokens } from 'aws-amplify/auth';
import {
  AppLoadContext,
  ClientLoaderFunction,
  ClientLoaderFunctionArgs,
  LoaderFunction,
  LoaderFunctionArgs,
  redirect,
} from 'react-router';
import { getRedirectLink } from './get-redirect-link.ts';
import { getAuthorizedData } from './handle-authorized-data.ts';
import { paths } from './paths.ts';
import { Tokens } from '@/amplify/types.ts';

type UserGroup = 'Users' | 'Admins';

type ProtectedLoaderOpts = {
  groups?: UserGroup[];
};

const isInGroup = (tokens: AuthTokens, groups: UserGroup[]) =>
  (tokens.accessToken.payload['cognito:groups'] as UserGroup[]).some(
    (group) => {
      return groups.includes(group);
    },
  );

const getRedirectResponse = (
  tokens: Tokens | undefined,
  request: Request,
  { groups }: ProtectedLoaderOpts,
) => {
  if (!tokens) {
    return redirect(
      getRedirectLink(paths.landing, new URL(request.url).pathname),
    );
  }

  if (!tokens.idToken.payload.preferred_username) {
    return redirect(
      getRedirectLink(paths.signup, new URL(request.url).pathname),
    );
  }

  if (groups && !isInGroup(tokens, groups)) {
    return redirect(paths.default);
  }
};

export const protectLoader = <
  Args extends { request: Request } = ClientLoaderFunctionArgs,
  Data extends ReturnType<ClientLoaderFunction> = null,
>(
  fn: (args: Args) => Data = () => null as Data,
  opts: ProtectedLoaderOpts = {},
) => {
  async function protectedLoader(args: Args) {
    const { tokens } = await getAuthorizedData();

    return getRedirectResponse(tokens, args.request, opts) || fn(args);
  }

  protectedLoader.hydrate = true;

  return protectedLoader;
};

export const protectServerLoader = <
  Args extends LoaderFunctionArgs<AppLoadContext>,
  Data extends ReturnType<LoaderFunction> = null,
>(
  fn: (args: Args) => Data = () => null as Data,
  opts: ProtectedLoaderOpts = {},
) =>
  async function protectedServerLoader(args: Args) {
    const tokens = (await args.context?.tokenProvider.getTokens()) as
      | Tokens
      | undefined;

    return getRedirectResponse(tokens, args.request, opts) || fn(args);
  };

export type ProtectedLoaderData<Loader extends ClientLoaderFunction> = Exclude<
  Awaited<ReturnType<Loader>>,
  Response
>;
